<template>
    <div class="container">
        <div class="search" style="display:flex">
            <el-form
                ref="queryFormRef"
                style="flex:1"
                :inline="true"
                :model="search"
                class="demo-form-inline"
                size="mini"
            >
                <el-form-item label="订单号：" prop="order_no">
                    <el-input
                        v-model.trim="search.order_no"
                        placeholder=" "
                        @keyup.enter.native="Search"
                    ></el-input>
                </el-form-item>
                <el-form-item label="订单联系邮箱：" prop="email">
                    <el-input
                        v-model.trim="search.email"
                        placeholder=" "
                        @keyup.enter.native="Search"
                    ></el-input>
                </el-form-item>
                <el-form-item label="创建时间：" prop="email">
                    <el-date-picker
                        v-model="search.createTime"
                        type="daterange"
                        range-separator="到"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        @change="Search"
                    />
                </el-form-item>
                <el-form-item label="型号：" prop="model">
                    <el-input
                        v-model.trim="search.model"
                        placeholder=" "
                        @keyup.enter.native="Search"
                    ></el-input>
                </el-form-item>
                <el-form-item label="区域：" prop="region">
                    <el-select
                        clearable
                        v-model="search.region"
                        @change="Search"
                    >
                        <el-option label="中国" value="cn"></el-option>
                        <el-option label="欧洲" value="eu"></el-option>
                        <el-option label="美洲" value="us"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        style="margin-left: 16px;"
                        class="btnClass"
                        @click="Search"
                        >搜索</el-button
                    >
                    <el-button
                        type="text"
                        class="textBtn"
                        style="padding-bottom: 0px;vertical-align: bottom;"
                        @click="clearCondition"
                        >重置</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
        <!-- 列表 -->
        <el-table :data="list" size="mini">
            <el-table-column label="用户账号" width="90">
                <template v-slot="scope">
                    <router-link
                        style="color:blue;cursor: pointer;"
                        :to="
                            '/iot/userinfo/' +
                                scope.row.uid +
                                '&' +
                                scope.row.region
                        "
                    >
                        <span>{{ scope.row.uid }}</span>
                    </router-link>
                </template>
            </el-table-column>
            <el-table-column label="渠道" width="70">
                <template v-slot="scope">
                    <span>{{
                        scope.row.channel === 1 ? '亚马逊' : '其他'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="订单号" width="180">
                <template v-slot="scope">
                    <span>{{ scope.row.order_no }}</span>
                </template>
            </el-table-column>
            <el-table-column label="订单联系邮箱" width="180">
                <template v-slot="scope">
                    <span>{{ scope.row.email }}</span>
                </template>
            </el-table-column>
            <el-table-column label="设备型号">
                <template v-slot="scope">
                    <span>{{ scope.row.model_code }}</span>
                </template>
            </el-table-column>
            <el-table-column label="uuid">
                <template v-slot="scope">
                    <span>{{ scope.row.uuid }}</span>
                </template>
            </el-table-column>
            <el-table-column label="区域">
                <template v-slot="scope">
                    <span>{{ scope.row.region }}</span>
                </template>
            </el-table-column>
            <el-table-column label="工单ID">
                <template v-slot="scope">
                    <span>{{ scope.row.ticket_number }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="创建时间(UTC)"
                width="170"
                prop="create_time"
                :formatter="
                    (row) => row.create_time && util.dateFormat(row.create_time)
                "
            >
            </el-table-column>
            <el-table-column label="备注" width="150" show-overflow-tooltip>
                <template v-slot="scope">
                    <span>{{ scope.row.remark }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
                <template v-slot="scope">
                    <el-button
                        type="info"
                        size="mini"
                        class="btnClass"
                        @click="openRemark(scope.row)"
                        >备注</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @current-change="handleCurrentChange"
            v-model:currentPage="search.page"
            :page-size="search.page_size"
            layout="total, prev, pager, next"
            :total="total"
            background
        >
        </el-pagination>
        <el-dialog
            v-model="remarkDialog"
            :modal="true"
            :append-to-body="true"
            title="修改备注"
            width="30%"
        >
            <el-form class="modify-uuid">
                <el-form-item label="" prop="pass">
                    <el-input
                        v-model="remark"
                        :rows="4"
                        type="textarea"
                        placeholder=""
                    />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="remarkDialog = false">取消</el-button>
                    <el-button type="primary" @click="updateRemark()"
                        >确定</el-button
                    >
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'

export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      util: util,
      search: {
        page: 1,
        page_size: 16,
        email: '',
        order_no: '',
        createTime: '',
        start_time: '',
        end_time: '',
        model: '',
        region: ''
      },
      list: [],
      total: 0,
      remarkDialog: false,
      remark: '',
      currentRow: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    getTimestamp(date) {
        return +date.getTime().toString().slice(0, 10)
    },
    handleCurrentChange(val) {
      this.search.page = val
      sessionStorage.setItem('currentPage',val);
      this.Search()
    },
    Search(){
      const start_time = this.search.createTime ? this.getTimestamp(this.search.createTime[0]) : ''
      const end_time = this.search.createTime ? this.getTimestamp(this.search.createTime[1]) : ''
      console.log('this.search: ',this.search, start_time, end_time)
      let filter = {
        ...this.search,
        start_time,
        end_time
      }
      delete filter.createTime
      this.api.WarrantyOrderList(filter).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.list
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    getSchoolWebModuleMessageListFunc(){
      setTimeout(() => {
        if(sessionStorage.getItem("currentPage") && sessionStorage.getItem("currentPage") !== 1){
          this.search.page = Number(sessionStorage.getItem("currentPage"))
        }
        this.Search()
      },0)
    },
    openRemark(row) {
        this.currentRow = row
        this.remark = row.remark
        this.remarkDialog = true
    },
    updateRemark() {
        this.api.WarrantyOrderUpdate({
            id: this.currentRow.id,
            remark: this.remark
        }).then(res => {
            if (res.data.code == 200) {
                this.$message.success('修改成功')
                this.remarkDialog = false
                this.Search()
                return
            }
            this.$message.error('调用失败:' + res.data.msg)
        })
    },
	clearCondition() {
		this.reset()
		this.Search()
	},
	reset() {
      this.search = {
        ...this.search,
        email: '',
        order_no: '',
        createTime: '',
        model: '',
        region: ''
      }
    },
  },
  filters: {},
  mounted () {
    this.getSchoolWebModuleMessageListFunc()
  },
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.container {
	font-family: 'Manrope';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	.search {
		background: #F0F2F5;
		padding-top: 16px;
		margin-bottom: 24px;
	}
	.el-form {
		::v-deep(.el-form-item__label) {
			font-family: 'Manrope';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			color: #000000;
            opacity: 0.8;
			padding-right: 0px;
		}
		.el-form-item {
			margin-left: 24px;
		}
	}
	.btnClass {
		color: #FFF;
		background-color: #FB6D07;
		border-color: #FB6D07;
	}
	.textBtn {
		color: #FB6D07;
	}
	::v-deep(thead) {
		font-family: 'Manrope';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		color: #000;
		opacity: 0.8;
		th {
			font-weight: 400;
			background-color: #F0F2F5;
		}
	}
  ::v-deep(tbody) {
    tr {
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      opacity: 0.8;
    }
  }

}
</style>
